body {
	overflow: hidden;
}

* {
	font-family: "Roboto";
	font-weight: 700;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}
.text-upper {
	text-transform: uppercase;
}

.text-primary {
	color: $primary-base;
}

.dash-grey {
	color: $group-name;
}

.btn-grey {
	color: $group-name;
	background: white !important;
}

::placeholder {
	font-weight: 400;
}

:-ms-input-placeholder {
	font-weight: 400;
}

::-ms-input-placeholder {
	font-weight: 400;
}

.edit-icon {
	width: fit-content !important;
	flex-grow: 0 !important;
	justify-content: end !important;
}

.profile-image {
	width: 56px;
	height: 56px;
	border-radius: 4px;
}

.back-icon {
	color: $primary-base;
	width: 7px;
	height: 12px;
}

.header-title {
	color: $primary-base;
	font-size: 16px;
	line-height: 125%;
}

.Mui-selected {
	background-color: #e37222 !important;
}

.MuiPickersDay-today {
	background-color: #bbbbbb !important;
	color: #fff !important;
}

.btn-custom-primary {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	background-color: $primary-base;
	color: white;
	border: 0;
	box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	font-size: 12px;
	font-weight: 700;
	&:active {
		background-color: $primary-base-focus;
	}
}

.dialog-wrapper {
	transition-delay: all 500ms ease-in-out;
	z-index: 10;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.2);
}

.links {
	margin-block: 10px;
	display: flex;
	gap: 8px;
	.button {
		flex-grow: 1;
		text-transform: uppercase;
		color: $group-name;
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		border-radius: 4px;
		font-size: 12px;
		line-height: 125%;
	}
}

button,
.button,
.cursor {
	cursor: pointer;
}

.remove-scroll-bar {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

div:has(> div.noSelected) {
	background-color: $grey-background;
	margin-top: 0 !important;
}

div:has(> div.hasSelected) {
	background-color: #fff !important;
	margin-top: 0 !important;
}
