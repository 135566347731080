@import "_vars";
@import "./globals";
@import "~@o4c/css/src/index";

.profile-detail {
	display: flex;
	flex-direction: column;
	gap: 8px;
	.name {
		font-weight: 400;
		font-size: 16px;
		line-height: 125%;
		color: #222222;
	}
	.group-team {
		display: flex;
		gap: 8px;
		align-items: center;
		.group,
		.team {
			font-size: 12px;
			line-height: 125%;
			color: $group-name;
		}
		.group {
			align-items: center;
			padding: 4px 12px;
			&.same {
				border-radius: 13px;
				background-color: $accent-base;
				color: white !important;
				font-size: 11px;
			}
			&.notsame {
				padding: 0;
			}
		}
	}
}

.manager-card {
	width: 343px;
	height: 144px;
	background: #ffffff;
	border: 1px solid #d6d6d6;
	box-shadow: 0px -0.25px 0.5px rgba(0, 0, 0, 0.0125),
		0px 1px 2px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	overflow: hidden;
	.top {
		border-bottom: 1px solid $grey-light;
		height: 50%;
		display: flex;
		align-items: center;
		gap: 12px;
		padding-left: 12px;
	}
	.bottom {
		height: 50%;
		display: flex;
		align-items: center;
		.left,
		.right {
			height: 100%;
			padding: 12px;
			width: 50%;
		}
		.timeslot {
			font-size: 11px;
			line-height: 125%;
			margin-bottom: 8px;
			display: flex;
			align-items: center;
		}
		.dash-grey {
			color: $group-name;
		}
		.location {
			font-size: 14px;
			line-height: 125%;
			font-weight: 400;
			color: black;
		}
		.left {
			border-right: 1px solid $grey-light;
		}
	}
}

// pages
.home-container {
	padding: 16px 0;
	background-color: $grey-background;
	.title-container {
		padding: 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		button {
			flex-grow: 0;
		}
	}
	.card-container-wrapper {
		margin: 20px 0;
		overflow-x: scroll;
		.card-container {
			display: flex;
			gap: 16px;
			padding: 0 16px;
		}
		.manager-card {
			flex-shrink: 0;
			width: 80%;
			max-width: 340px;
		}
		.last-card {
			flex-shrink: 0;
			width: 1px;
			height: 144px;
		}
	}

	.btn-custom-primary {
		margin: 0 16px;
		width: calc(100% - 32px);
	}
}

.all-schedules {
	display: flex;
	height: 100vh;
	flex-direction: column;
	background-color: $grey-background;
	.date-actions {
		background-color: white;
		height: fit-content;
		flex-grow: 0;
		padding: 12px 16px;
		display: flex;
		gap: 8px;
		border-bottom: 1px solid $grey-light;
		button {
			color: $group-name;
			background-color: white;
			border: 1px solid rgba(0, 0, 0, 0.2);
			display: flex;
			align-items: center;
			padding: 8px 12px;
			gap: 8px;
			height: 32px;
			border-radius: 4px;
			font-weight: 400;
			font-size: 14px;
			line-height: 125%;
		}
		button.selected {
			box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
			background: #00a1de;
			color: white !important;
			border: 0;
		}
	}
	.schedules {
		flex-grow: 1;
		overflow-y: scroll;
		.schedule-actions {
			padding: 16px;
			.input-label-row {
				span {
					display: none;
				}
			}
		}
		.manager-cards {
			display: flex;
			gap: 16px;
			flex-direction: column;
			padding: 8px 16px 40px 16px;
			.manager-card {
				width: 100%;
				.profile-detail {
					flex-grow: 1;
				}
				.top svg {
					align-content: flex-end;
					margin-right: 5px;
				}
			}
		}
	}
}

.edit-schedule,
.manage-profiles {
	display: flex;
	height: 100vh;
	flex-direction: column;
	background-color: $grey-background;
	.header {
		display: flex;
		gap: 22px;
		align-items: center;
		padding: 18px 16px 18px 30px;
		background-color: white;
		border-bottom: 1px solid $grey-light;
		i {
			margin-bottom: 4px;
			cursor: pointer;
		}
	}

	.search-container {
		padding: 23px 0;
		.input-label-row {
			span {
				display: none !important;
			}
		}
	}

	.profiles-container {
		padding: 16px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		gap: 8px;
		.manager-card {
			width: 100%;
			height: 80px;
			flex-shrink: 0;
			.top {
				padding-right: 22px;
				height: 100%;
				.profile-detail {
					flex-grow: 1;
					.group {
						padding: 0;
					}
				}
			}
		}
	}

	.schedules-container {
		display: flex;
		gap: 16px;
		overflow-y: scroll;
		flex-direction: column;
		padding: 16px 16px 40px 16px;
		.schedule-cards {
			display: flex;
			flex-direction: column;
			gap: 16px;
			button {
				color: $group-name;
				background-color: white;
				border: 1px solid rgba(0, 0, 0, 0.2);
				display: flex;
				align-items: center;
				padding: 8px 12px;
				gap: 8px;
				height: 32px;
				border-radius: 4px;
				font-weight: 400;
				font-size: 14px;
				line-height: 125%;
				width: fit-content;
			}
			button.selected {
				box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
				background: #00a1de;
				color: white !important;
				border: 0;
			}
			.bottom {
				margin-top: 8px;
			}

			.schedule-card {
				display: flex;
				align-items: center;
				background: #ffffff;
				border: 1px solid $grey-light;
				border-radius: 4px;
				width: 100%;
				.left {
					border-right: 1px solid $grey-light;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					width: 48px;
					text-align: center;
					height: 100%;
					.weekday {
						font-size: 11px;
						line-height: 125%;
						text-transform: uppercase;
						color: $group-name;
					}
					.date {
						font-size: 20px;
						line-height: 125%;
					}
				}
				.right {
					flex-grow: 1;
					flex-direction: column;
					.top,
					.bottom {
						width: 100%;
						height: 50%;
						padding: 12px;
						.timeslot {
							text-transform: uppercase;
							color: $primary-base;
							font-size: 11px;
							margin-bottom: 8px;
						}
						.locs {
							display: flex;
							flex-wrap: wrap;
							gap: 8px;
						}
					}
					.top {
						border-bottom: 1px solid $grey-light;
					}
				}
			}
		}
	}
}

.profile-edit-form {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	&.remove {
		position: relative;
		width: 100%;
		.edit-body .basic {
			display: flex;
			align-items: center;
			padding: 0;
			border-bottom: 0;
			margin-bottom: 0;
		}
		.group {
			padding: 0;
		}
		.edit-body {
			padding: 24px 16px;
		}
	}
	.dialog-wrapper {
		transition: all 0ms;
	}
	.edit-header {
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 14px 16px;
		.edit-title {
			font-size: 16px;
			line-height: 125%;
		}
	}
	.edit-body {
		flex-grow: 1;
		border-bottom: 1px solid $grey-light;
		border-top: 1px solid $grey-light;
		background-color: $grey-background;
		padding: 16px 24px;
		.basic {
			display: flex;
			gap: 16px;
			padding-bottom: 16px;
			border-bottom: #d6d6d6 1px solid;
			margin-bottom: 16px;
			.profile-image {
				height: 80px;
				width: 80px;
				flex-shrink: 0;
				background-size: cover;
				border-radius: 4px;
				position: relative;
				.inner {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.4),
						rgba(0, 0, 0, 0.4)
					);
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		input {
			font-weight: 400;
		}
		.text-groups {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
		.input-label-row {
			display: block !important;
			span.input-label-optional {
				font-size: 12px;
				line-height: 125%;
				font-weight: 400;
			}
		}
	}
	.edit-footer {
		padding: 16px 16px 32px 16px;
		background-color: white;
		display: flex;
		gap: 16px;
		button.invisible {
			.button-text {
				color: red !important;
			}
		}
		i {
			color: red !important;
			font-size: 13px !important;
		}
	}
}

.member-schedule {
	.header {
		display: felx;
		.header-title {
			flex-grow: 1;
		}
		.profile-image {
			width: 32px;
			height: 32px;
		}
	}
	.middle,
	.right {
		padding: 12px;
		width: calc(50% - 24px);
		.timeslot {
			text-transform: uppercase;
			color: $primary-base;
			font-size: 11px;
			margin-bottom: 8px;
			line-height: 125%;
		}
		.location {
			color: #222;
			font-size: 14px;
			line-height: 125%;
			font-weight: 400;
		}
	}
	.middle {
		border-right: 1px solid $grey-light;
	}
}

.o4c.input-row {
	.o4c.text-field {
		background: #fff !important;
		border: 1px solid #d6d6d6 !important;
		color: #222222 !important;
		&:disabled {
			background-color: $grey-lighter;
		}
		&::placeholder {
			color: #bbbbbb !important;
		}
	}
	.icon {
		color: #bbb !important;
	}
}
