$primary-base: #00a1de !important;
$primary-base-focus: rgba(0, 145, 200, 1) !important;
$grey-light: #d6d6d6 !important;
$grey-background: #f6f6f6 !important;
$accent-base: #e37222 !important;
$group-name: #6b6b6b !important;
$grey-dark-background: #121212 !important;
$group-name-dark: #cccccc !important;
$grey-dark-secondary: #2d2d2d;
$grey-lighter: #efefef !important;
$grey-lighter-dark: #161616 !important;
