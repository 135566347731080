@import "_vars";

@media (prefers-color-scheme: dark) {
	.dash-grey {
		color: $group-name-dark;
	}

	.MuiPickersDay-today {
		background-color: #6b6b6b !important;
	}

	.btn-grey,
	.links .button,
	button.default {
		color: $group-name-dark;
		background: #1e1e1e !important;
		border: 1px solid rgba(255, 255, 255, 0.1) !important;
	}

	.btn-custom-primary,
	button.btn-custom-primary {
		box-shadow: none !important;
		background-color: $primary-base;
		color: white !important;
	}

	.profile-detail {
		.name {
			color: white;
		}
		.group-team {
			.group,
			.team {
				color: #ccc !important;
			}
		}
	}

	.manager-card {
		background: #1e1e1e;
		border: 1px solid #2d2d2d;
		box-shadow: 0px -0.25px 0.5px rgba(0, 0, 0, 0.0125),
			0px 1px 2px rgba(0, 0, 0, 0.05);
		.top {
			button.edit-icon {
				border: 0 !important;
			}
			border-bottom: 1px solid #2d2d2d !important;
		}
		.bottom {
			.dash-grey {
				color: $group-name-dark;
			}
			.location {
				color: #fff;
			}
			.left {
				border-right: 1px solid #2d2d2d !important;
			}
		}
	}

	// pages
	.home-container {
		background-color: $grey-dark-background;
	}
	.all-schedules {
		background-color: $grey-dark-background;
		.date-actions {
			background-color: #1e1e1e;
			border-bottom: 1px solid #2d2d2d !important;
			button {
				color: $group-name-dark;
				background: #1e1e1e !important;
				border: 1px solid rgba(255, 255, 255, 0.1) !important;
			}
			button.selected {
				box-shadow: none;
				background: #00a1de !important;
				border: 1px solid #00a1de !important;
				color: white !important;
			}
		}
	}

	.edit-schedule,
	.manage-profiles {
		background-color: $grey-dark-background;
		.header {
			background-color: #1e1e1e;
			border-bottom: 1px solid #2d2d2d !important;
		}
		.schedules-container {
			.month-name {
				color: $group-name-dark;
			}
			.schedule-cards {
				button {
					color: $group-name-dark;
					background: #1e1e1e !important;
					border: 1px solid rgba(255, 255, 255, 0.1) !important;
				}
				button.selected {
					box-shadow: none;
					background: #00a1de !important;
					border: 1px solid #00a1de !important;
					color: white !important;
				}
				.schedule-card {
					background: #1e1e1e;
					border: 1px solid #2d2d2d !important;
					.left {
						border-right: 1px solid #2d2d2d !important;
						.weekday,
						.date {
							color: $group-name-dark;
						}
					}
					.right .top {
						border-bottom: 1px solid #2d2d2d !important;
					}
				}
			}
		}
	}

	.profile-edit-form {
		&.remove {
			overflow: hidden !important;
			.edit-body .basic {
				border-bottom: none !important;
			}
		}
		.edit-header {
			background-color: #1e1e1e;
			color: white;
		}
		.edit-body {
			border-bottom: 1px solid #2d2d2d !important;
			border-top: 1px solid #2d2d2d !important;
			background-color: $grey-dark-background;
			.basic {
				border-bottom: 1px solid #2d2d2d !important;
			}
		}
		.edit-footer {
			background-color: #1e1e1e;
			button.invisible {
				border: 0 !important;
			}
			button {
				box-shadow: none !important;
			}
		}
	}

	.member-schedule {
		.middle,
		.right {
			.location {
				color: white;
			}
		}
		.middle {
			border-right: 1px solid #2d2d2d !important;
		}
	}

	// overr writes
	.MuiPaper-root {
		background-color: #1e1e1e !important;
	}

	.MuiPickersDay-today {
		color: #1e1e1e !important;
		border-color: #bbb !important;
	}

	.o4c.input-row {
		.o4c.text-field {
			background: #1e1e1e !important;
			border: 1px solid #2d2d2d !important;
			box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
			color: white !important;
			&:disabled {
				background-color: $grey-lighter-dark;
				color: $group-name;
			}
			&::placeholder {
				color: $group-name;
			}
		}
		.icon {
			color: #bbb !important;
		}
	}

	.o4c.input-group .input-label-row {
		.input-label,
		.input-label-optional {
			color: #ccc !important;
		}
	}
	div:has(> div.noSelected) {
		background-color: black !important;
	}

	div:has(> div.hasSelected) {
		background-color: #1e1e1e !important;
	}
}
